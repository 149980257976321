<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10" style="background: #f5f5f5">
              <v-card-title style="background: #f5f5f5">
                Contact Information
              </v-card-title>

              <v-data-table
                style="background: #f5f5f5"
                elevation="1"
                :headers="headers"
                :items="orders"
                single-expand
                show-expand
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    Name : {{ item.sender_name }} <br />
                    Email : {{ item.sender_email }} <br />
                    Message : {{ item.message }}
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-row justify="end">
          <v-btn icon class="float-right mr-5"
            ><v-icon large>mdi-close-thick</v-icon></v-btn
          >
        </v-row>

        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col>Name :</v-col>
              <v-col>{{ contact.sender_name }}</v-col>
            </v-row>
            <v-row>
              <v-col>Email :</v-col>
              <v-col>{{ contact.sender_email }}</v-col>
            </v-row>
            <v-row>
              <v-col>Message :</v-col>
              <v-col>{{ contact.message }}</v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-icon small class="mr-2" @click="editItem(item)"
                    >mdi-view-list</v-icon
                  > -->
  </v-container>
</template>



<script>
export default {
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Sender name", value: "sender_name" },
        { text: "Sender Email", value: "sender_email" },
        { text: "Subject", value: "subject" },
        { text: "Attended", value: "is_attended" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      orders: [],
      contact: {},
    };
  },
  methods: {
    initialize() {
      axios.get("site/all_contact/").then((response) => {
     
        this.orders = response.data.data;
      });
    },
    editItem(item) {
      (this.contact = item), (this.dialog = true);
    },
    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`site/delete_contact/${item.id}/`).then((response) => {
          this.orders = this.orders.filter((contact) => contact.id !== item.id);
        });
      }
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>